import * as React from "react"
import ScrollAnimation from 'react-animate-on-scroll';
import LocRatingMap from '@Components/map/loc-rating-map';
import { MapMarkerInfoBox } from '@Components/map/map-marker';
import  './PropertyMap.scss';

const PropertyMap = ( props ) => {
     let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = props.property_map_lat      
    mapItem['lng']  = props.property_map_lng
    const mapMarkerImageShowFlag = 0;
    let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
    mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );
  
  return(
<div className="property-map d-xl-flex">
  
    <ScrollAnimation animateIn='slideIn' className="map">
        <LocRatingMap data={mapItems} />
        </ScrollAnimation>
  
    
</div>
)
  }

export default PropertyMap
